import React from 'react';
import '../../../App.css';


function Rooms() {
    return (
        <>
            <section className="jarallax">
            <div className="ja-container jarallax-container-1" >
                    {/* <img className="img-jarallax"  src="images/background/7.jpg"  alt="jarallax" /> */}
                </div>
                
                <div className="container bg-size-style">
                    <div className="row gx-4 bg-size-style">
                        <div className="col-lg-12 text-center bg-size-style">
                            <h2 className="title center">Our Rooms
                                <span className="small-border" />
                            </h2>
                        </div>
                        <div className="col-lg-4">
                            <div className="de-room">
                                <div className="d-image">
                                    <div className="d-label">only 2 room left</div>
                                    <div className="d-details">
                                        <span className="d-meta-1">
                                            <img src="images/ui/user.svg" alt='user' />2 Guests
                                        </span>
                                        <span className="d-meta-2">
                                            <img src="images/ui/floorplan.svg" alt='floorplan' />30 ft
                                        </span>
                                    </div>
                                    <a href="02-room-single.html">
                                        <img src="images/room/1.jpg" className="img-fluid" alt='room' />
                                        <img src="images/room/1-alt.jpg" className="d-img-hover img-fluid" alt='room' />
                                    </a>
                                </div>
                                <div className="d-text">
                                    <h3>Standard Room</h3>
                                    <p>Most hotels and major hospitality companies have set industry standards to classify hotel types. An upscale full-service hotel facility offers luxury...</p>
                                    <a href="02-room-single.html" className="btn-line"><span>Book Now For $29</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="de-room">
                                <div className="d-image">
                                    <div className="d-label">only 1 room left</div>
                                    <div className="d-details">
                                        <span className="d-meta-1">
                                            <img src="images/ui/user.svg" alt='user' />2 Guests
                                        </span>
                                        <span className="d-meta-2">
                                            <img src="images/ui/floorplan.svg" alt='floorplan' />35 ft
                                        </span>
                                    </div>
                                    <a href="02-room-single.html">
                                        <img src="images/room/2.jpg" className="img-fluid" alt='room' />
                                        <img src="images/room/2-alt.jpg" className="d-img-hover img-fluid" alt='room' />
                                    </a>
                                </div>
                                <div className="d-text">
                                    <h3>Deluxe Room</h3>
                                    <p>Most hotels and major hospitality companies have set industry standards to classify hotel types. An upscale full-service hotel facility offers luxury...</p>
                                    <a href="02-room-single.html" className="btn-line"><span>Book Now For $39</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="de-room">
                                <div className="d-image">
                                    <div className="d-label">only 3 room left</div>
                                    <div className="d-details">
                                        <span className="d-meta-1">
                                            <img src="images/ui/user.svg" alt='user' />2 Guests
                                        </span>
                                        <span className="d-meta-2">
                                            <img src="images/ui/floorplan.svg" alt='floorplan' />40 ft
                                        </span>
                                    </div>
                                    <a href="02-room-single.html">
                                        <img src="images/room/3.jpg" className="img-fluid" alt='room' />
                                        <img src="images/room/3-alt.jpg" className="d-img-hover img-fluid" alt='room' />
                                    </a>
                                </div>
                                <div className="d-text">
                                    <h3>Premier Room</h3>
                                    <p>Most hotels and major hospitality companies have set industry standards to classify hotel types. An upscale full-service hotel facility offers luxury...</p>
                                    <a href="02-room-single.html" className="btn-line"><span>Book Now For $49</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    );
}

export default Rooms;