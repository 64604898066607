import React from 'react';

function Header() {
  return (
    <>
<div id="wrapper">
  {/* header begin */}
  <header className="header-fullwidth transparent">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="de-flex">
            <div className="de-flex-col">
              {/* logo begin */}
              <div id="logo">
                <a href="/">
                  <img className="logo logo-sm" src="images/logo.png" alt="logo" />
                </a>
              </div>
              {/* logo close */}
            </div>
            <div className="de-flex-col nav-style">
              <ul id="mainmenu" class="no-separator">
                <li><a href="#/">Home</a></li>
                <li><a href="02-about.html">About</a></li>
                <li><a href="#/">Rooms</a></li>
                <li><a href="02-booking.html">Booking</a></li>
                <li><a href="02-contact.html">Contact</a></li>
              </ul>
            </div>
            <div className="de-flex-col">
              <div className="d-extra">
                <a className="btn-main" href="02-booking.html">Booking</a>
              </div>
              <div id="menu-btn" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  {/* header close */}
  {/* menu overlay begin */}
  <div id="menu-overlay" className="slideDown">
    <div className="container-fluid">
      <div className="row-fluid">
        <div className="col-md-12">
          <div id="mo-button-close">
            <div className="line-1" />
            <div className="line-2" />
          </div>
          <div className="pt80 pb80">
            <div className="mo-nav text-center">
              <a href="02-index.html">
                <img className="logo" src="images/logo.png" alt="logo" />
              </a>
              <div className="spacer-single" />
              {/* mainmenu begin */}
              <ul id="mo-menu">
                <li>Home</li>
                <li><a href="02-about.html">About</a></li>
                <li>Rooms</li>
                <li><a href="02-booking.html">Booking</a></li>
                <li><a href="offer.html">Offers</a></li>
                <li><a href="02-blog.html">Blog</a></li>
                <li><a href="02-gallery.html">Gallery</a></li>
                <li><a href="02-contact.html">Contact</a></li>
              </ul>
              {/* mainmenu close */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* menu overlay close */}

    </>
  );
}

export default Header;