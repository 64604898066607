import React from 'react';

function Hero() {
  return (
    <>
        <div>
  {/* float text begin */}
  <div className="float-text">
    <div className="de_social-icons">
      <a href="/#"><i className="fa fa-facebook fa-lg" /></a>
      <a href="/#"><i className="fa fa-twitter fa-lg" /></a>
      <a href="/#"><i className="fa fa-instagram fa-lg" /></a>
    </div>
    <span><a href="/#">Book Now</a></span>
  </div>
  {/* float text close */}
  <section className="no-top no-bottom jarallax vertical-center" data-video-src="mp4:video/local-video-1.mp4">
    <div className="de-overlay v-center">
      <div className="container" data-aos="fade-up" data-aos-duration="9000" data-aos-delay="1000">
        <div className="row">
          <div className="col-lg-6">
            <h1>Enjoy Your<br />Dream Vacation</h1>
            <p className="lead">The Seaside Hotel is the right choice for visitors who are searching for a combination of charm, peace and, comfort.</p>
            <a className="btn-main" href="/#"><span>Choose Room</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</>
  );
}

export default Hero;