import React from 'react';

function Footer() {
  return (
    <>
    <footer className="no-top pl20 pr20">
  <div className="subfooter bg-size-style">
    <div className="container-fluid bg-size-style">
      <div className="row bg-size-style">
        <div className="col-md-6 bg-size-style" >© Copyright 2024 - Seaside Hotel by <span className="id-color">Designesia</span></div>
        <div className="col-md-6 text-right bg-size-style">
          <div className="social-icons bg-size-style">
            <a href="/#"><i className="fa fa-facebook fa-lg" /></a>
            <a href="/#"><i className="fa fa-twitter fa-lg" /></a>
            <a href="/#"><i className="fa fa-rss fa-lg" /></a>
            <a href="/#"><i className="fa fa-google-plus fa-lg" /></a>
            <a href="/#"><i className="fa fa-skype fa-lg" /></a>
            <a href="/#"><i className="fa fa-dribbble fa-lg" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a href="/#" id="back-to-top" class="show" />
</footer>

    </>
  );
}

export default Footer;