import React from 'react';

import Header from './client/layouts/Header';
import Footer from './client/layouts/Footer';
import Landing from './client/pages/Home/Landing';

function App() {
  return (
    <>
        <Header />
        <Landing />
        <Footer />
    </>
  );
}

export default App;