import React from 'react';

import Hero from './Hero';
import Info from './Info';
import Rooms from './Rooms';
import Testimonials from './Testimonials';

function Landing() {
  return (
    <>
      {/* content begin */}
      <div id="content" className="no-bottom no-top">
        <Hero />
        <Info />
        <Rooms />
        <Testimonials />
      </div>
    </>
  );
}

export default Landing;