import React from 'react';
import '../../../App.css';

function Testimonials() {
    return (
        <>
            <section className="jarallax">
            <div className="ja-container jarallax-container-2" ></div>
                {/* <img src="images/background/4.jpg" className="jarallax-img" alt='background' /> */}
                <div className="container">
                    <div className="row gx-4">
                        <div className="col-lg-12 text-center">
                            <h2 className="title center">Testimonials
                                <span className="small-border" />
                            </h2>
                        </div>
                        <div className="col-md-8 offset-md-2 wow fadeInUp">
                            <div id="testimonial-carousel-single" className="owl-carousel owl-theme wow fadeInUp">
                                <blockquote className="testimonial-big">
                                    <span className="d-testi">The rooms were clean, very comfortable, and the staff was amazing. They went over and beyond to help make our stay enjoyable. I highly recommend this hotel for anyone visiting downtown</span>
                                    <span className="name">Benedict Mervine, Customer</span>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Testimonials;